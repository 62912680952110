import * as React from 'react';
import { classes } from './offering-action.st.css';
import {
  CatalogOfferingInjectedProps,
  withOfferingContext,
} from '../../../catalog-offering-context';
import { OfferingIntent } from '../../../../constants';
import { WIDGET_BI_REFERRAL } from '../../../../adapters/reporting/bi-logger/bi.const';
import { InjectedTranslateProps, translate } from 'react-i18next';

interface OfferingActionProps {
  onAction(intent: string, biReferral: string): void;
}

class OfferingAction extends React.Component<
  OfferingActionProps & CatalogOfferingInjectedProps & InjectedTranslateProps
> {
  render() {
    const { offeringViewModel, onAction, deviceType } = this.props;
    const actionCTA = offeringViewModel.action.cta;
    const actionStyle = `${classes.action} ${
      classes[`action-${offeringViewModel.action.type}`]
    } ${classes[deviceType] || ''} offering-widget__cta`;
    return (
      <div className={classes.actionContainer}>
        <button
          className={actionStyle}
          data-hook="tile-action"
          onClick={() =>
            onAction(
              this.modifyNoBookFlowIntent(),
              WIDGET_BI_REFERRAL.WIDGET_BUTTON,
            )
          }
        >
          {actionCTA}
        </button>
      </div>
    );
  }

  private modifyNoBookFlowIntent() {
    return this.props.offeringViewModel.displayOnlyNoBookFlow
      ? OfferingIntent.SHOW_DETAILS
      : OfferingIntent.BOOK_OFFERING;
  }
}

export default translate()<OfferingActionProps>(
  withOfferingContext()(OfferingAction),
);
